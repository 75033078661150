export const CampusAdvantagesUnderLogo = () => import('../../components/CampusAdvantagesUnderLogo.vue' /* webpackChunkName: "components/campus-advantages-under-logo" */).then(c => wrapFunctional(c.default || c))
export const CampusDropDownMenuHeader = () => import('../../components/CampusDropDownMenuHeader.vue' /* webpackChunkName: "components/campus-drop-down-menu-header" */).then(c => wrapFunctional(c.default || c))
export const DetaiCampusAvantage1 = () => import('../../components/DetaiCampusAvantage1.vue' /* webpackChunkName: "components/detai-campus-avantage1" */).then(c => wrapFunctional(c.default || c))
export const DetaiCampusAvantage2 = () => import('../../components/DetaiCampusAvantage2.vue' /* webpackChunkName: "components/detai-campus-avantage2" */).then(c => wrapFunctional(c.default || c))
export const DetaiCampusAvantage3 = () => import('../../components/DetaiCampusAvantage3.vue' /* webpackChunkName: "components/detai-campus-avantage3" */).then(c => wrapFunctional(c.default || c))
export const DetailCampusBannierMapBlock = () => import('../../components/DetailCampusBannierMapBlock.vue' /* webpackChunkName: "components/detail-campus-bannier-map-block" */).then(c => wrapFunctional(c.default || c))
export const DetailCampusCards = () => import('../../components/DetailCampusCards.vue' /* webpackChunkName: "components/detail-campus-cards" */).then(c => wrapFunctional(c.default || c))
export const DetailCampusDiplomasList = () => import('../../components/DetailCampusDiplomasList.vue' /* webpackChunkName: "components/detail-campus-diplomas-list" */).then(c => wrapFunctional(c.default || c))
export const DetailCampusOffresDAlternance = () => import('../../components/DetailCampusOffresDAlternance.vue' /* webpackChunkName: "components/detail-campus-offres-d-alternance" */).then(c => wrapFunctional(c.default || c))
export const DetailCampusSecondScreen = () => import('../../components/DetailCampusSecondScreen.vue' /* webpackChunkName: "components/detail-campus-second-screen" */).then(c => wrapFunctional(c.default || c))
export const DetailCamusSliderNews = () => import('../../components/DetailCamusSliderNews.vue' /* webpackChunkName: "components/detail-camus-slider-news" */).then(c => wrapFunctional(c.default || c))
export const Diplomes = () => import('../../components/Diplomes.vue' /* webpackChunkName: "components/diplomes" */).then(c => wrapFunctional(c.default || c))
export const Diplomes3Collone = () => import('../../components/Diplomes3Collone.vue' /* webpackChunkName: "components/diplomes3-collone" */).then(c => wrapFunctional(c.default || c))
export const DiplomesDropDownMenu = () => import('../../components/DiplomesDropDownMenu.vue' /* webpackChunkName: "components/diplomes-drop-down-menu" */).then(c => wrapFunctional(c.default || c))
export const DiplomesHomePage = () => import('../../components/DiplomesHomePage.vue' /* webpackChunkName: "components/diplomes-home-page" */).then(c => wrapFunctional(c.default || c))
export const DomaineHomePage = () => import('../../components/DomaineHomePage.vue' /* webpackChunkName: "components/domaine-home-page" */).then(c => wrapFunctional(c.default || c))
export const ErrorFieldHandler = () => import('../../components/ErrorFieldHandler.vue' /* webpackChunkName: "components/error-field-handler" */).then(c => wrapFunctional(c.default || c))
export const FirstScreenSliderHomePage = () => import('../../components/FirstScreenSliderHomePage.vue' /* webpackChunkName: "components/first-screen-slider-home-page" */).then(c => wrapFunctional(c.default || c))
export const FirstScreenSliderNews = () => import('../../components/FirstScreenSliderNews.vue' /* webpackChunkName: "components/first-screen-slider-news" */).then(c => wrapFunctional(c.default || c))
export const FormErrorPopUp = () => import('../../components/FormErrorPopUp.vue' /* webpackChunkName: "components/form-error-pop-up" */).then(c => wrapFunctional(c.default || c))
export const HeaderCampus = () => import('../../components/HeaderCampus.vue' /* webpackChunkName: "components/header-campus" */).then(c => wrapFunctional(c.default || c))
export const HeaderCampusDetail = () => import('../../components/HeaderCampusDetail.vue' /* webpackChunkName: "components/header-campus-detail" */).then(c => wrapFunctional(c.default || c))
export const HeaderCandidats = () => import('../../components/HeaderCandidats.vue' /* webpackChunkName: "components/header-candidats" */).then(c => wrapFunctional(c.default || c))
export const HeaderContactPage = () => import('../../components/HeaderContactPage.vue' /* webpackChunkName: "components/header-contact-page" */).then(c => wrapFunctional(c.default || c))
export const HeaderDiploma = () => import('../../components/HeaderDiploma.vue' /* webpackChunkName: "components/header-diploma" */).then(c => wrapFunctional(c.default || c))
export const HeaderHomePage = () => import('../../components/HeaderHomePage.vue' /* webpackChunkName: "components/header-home-page" */).then(c => wrapFunctional(c.default || c))
export const HeaderIcfaDetails = () => import('../../components/HeaderIcfaDetails.vue' /* webpackChunkName: "components/header-icfa-details" */).then(c => wrapFunctional(c.default || c))
export const HeaderJPO = () => import('../../components/HeaderJPO.vue' /* webpackChunkName: "components/header-j-p-o" */).then(c => wrapFunctional(c.default || c))
export const HeaderLesAtouts = () => import('../../components/HeaderLesAtouts.vue' /* webpackChunkName: "components/header-les-atouts" */).then(c => wrapFunctional(c.default || c))
export const HeaderMentionsLegales = () => import('../../components/HeaderMentionsLegales.vue' /* webpackChunkName: "components/header-mentions-legales" */).then(c => wrapFunctional(c.default || c))
export const HeaderNewsPage = () => import('../../components/HeaderNewsPage.vue' /* webpackChunkName: "components/header-news-page" */).then(c => wrapFunctional(c.default || c))
export const HeaderOffresDAlternance = () => import('../../components/HeaderOffresDAlternance.vue' /* webpackChunkName: "components/header-offres-d-alternance" */).then(c => wrapFunctional(c.default || c))
export const HeaderPolitiqueDeConfidentialite = () => import('../../components/HeaderPolitiqueDeConfidentialite.vue' /* webpackChunkName: "components/header-politique-de-confidentialite" */).then(c => wrapFunctional(c.default || c))
export const JPOBannier = () => import('../../components/JPOBannier.vue' /* webpackChunkName: "components/j-p-o-bannier" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const TabPathHomePage = () => import('../../components/tabPathHomePage.vue' /* webpackChunkName: "components/tab-path-home-page" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
